import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RouteConstants } from './shared/constants/route.constant';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { unsavedChangesGuard } from './shared/guards/unsaved-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '',
    loadComponent: () => import('./shell/shell.component'),
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: RouteConstants.DASHBOARD_ROUTE,
        loadComponent: () => import('./dashboard/dashboard.page'),
      },
      {
        path: RouteConstants.GROUPS_ROUTE,
        children: [
          {
            path: ':uid',
            loadChildren: () =>
              import('./groups/edit-group/edit-group.module').then(
                (m) => m.EditGroupModule,
              ),
          },
          {
            path: '',
            loadComponent: () => import('./groups/groups.component'),
          },
        ],
      },
      {
        path: RouteConstants.ORGANIZATIONS_ROUTE,
        children: [
          {
            path: ':uid',
            loadChildren: () =>
              import(
                './organizations/single-organization/single-organization.module'
              ).then((m) => m.SingleOrganizationModule),
          },
          {
            path: '',
            loadChildren: () =>
              import('./organizations/organizations.module').then(
                (m) => m.OrganizationsModule,
              ),
          },
        ],
      },
      {
        path: RouteConstants.CHAT_ROUTE,
        loadChildren: () =>
          import('./chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: RouteConstants.CHAT_ROUTE,
        loadChildren: () =>
          import('./chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: RouteConstants.CLIENTS_ROUTE,
        children: [
          {
            path: ':uid',
            loadChildren: () =>
              import('./clients/single-client/single-client.module').then(
                (m) => m.SingleClientModule,
              ),
          },
          {
            path: '',
            loadChildren: () =>
              import('./clients/clients.module').then((m) => m.ClientsModule),
          },
        ],
      },

      {
        path: RouteConstants.SETTINGS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./settings/settings.module').then(
                (m) => m.SettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_WORKOUT_EXERCISE_ROUTE,
            loadChildren: () =>
              import(
                './settings/workout-exercise-settings/workout-exercise-settings.module'
              ).then((m) => m.WorkoutExerciseSettingsModule),
          },
          {
            path: RouteConstants.SETTINGS_MEALS_ROUTE,
            loadChildren: () =>
              import('./settings/meals-settings/meals-settings.module').then(
                (m) => m.MealsSettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_HABIT_CATEGORIES_ROUTE,
            loadChildren: () =>
              import('./settings/habits-settings/habits-settings.module').then(
                (m) => m.HabitsSettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_GOALS_METRICS_ROUTE,
            loadChildren: () =>
              import(
                './settings/goals-metrics-settings/goals-metrics-settings.module'
              ).then((m) => m.GoalsMetricsSettingsModule),
          },
        ],
      },

      {
        path: RouteConstants.CONFIGURE_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./settings/translation/translation.module').then(
                (m) => m.TranslationModule,
              ),
            canActivate: [RoleGuard],
          },
          {
            path: RouteConstants.TRANSLATION_INGREDIENTS_ROUTE,
            loadChildren: () =>
              import(
                './settings/translation/translation-ingredients/translation-ingredients.module'
              ).then((m) => m.TranslationIngredientsModule),
          },

          {
            path: RouteConstants.TRANSLATION_MEALS_ROUTE,
            loadChildren: () =>
              import(
                './settings/translation/translation-meals/translation-meals.module'
              ).then((m) => m.TranslationMealsModule),
          },
        ],
      },

      {
        path: RouteConstants.COACHES_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./coaches/coaches.module').then((m) => m.CoachesModule),
          },
        ],
      },
      {
        path: RouteConstants.TEAMS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./teams/teams.module').then((m) => m.TeamsModule),
          },
        ],
      },
      {
        path: RouteConstants.EXERCISES_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./exercises/exercises.module').then(
                (m) => m.ExercisesModule,
              ),
          },
        ],
      },
      {
        path: RouteConstants.WORKOUTS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./workouts/workouts.module').then(
                (m) => m.WorkoutsModule,
              ),
          },
          {
            path: ':uid',
            loadChildren: () =>
              import('./workouts/single-workout/single-workout.module').then(
                (m) => m.SingleWorkoutModule,
              ),
          },
        ],
      },

      {
        path: RouteConstants.WORKOUTS_ADD_ROUTE,
        children: [
          {
            path: ':uid',
            loadChildren: () =>
              import('./workouts/single-workout/single-workout.module').then(
                (m) => m.SingleWorkoutModule,
              ),
          },
        ],
      },
      {
        path: RouteConstants.WORKOUT_PROGRAMS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./workout-programs/workout-programs.module').then(
                (m) => m.WorkoutProgramsModule,
              ),
          },
          {
            path: ':uid',
            loadChildren: () =>
              import(
                './workout-programs/single-workout-program/single-workout-program.module'
              ).then((m) => m.SingleWorkoutProgramModule),
          },
        ],
      },
      {
        path: RouteConstants.DAILY_WORKOUTS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./daily-workouts/daily-workouts.module').then(
                (m) => m.DailyWorkoutsModule,
              ),
          },

          {
            path: ':uid',
            loadChildren: () =>
              import(
                './daily-workouts/single-daily-workouts/single-daily-workouts.module'
              ).then((m) => m.SingleWodProgramModule),
          },
        ],
      },

      {
        path: RouteConstants.INGREDIENTS_ROUTE,
        loadChildren: () =>
          import('./ingredients/ingredients.module').then(
            (m) => m.IngredientsModule,
          ),
      },

      {
        path: RouteConstants.MEAL_PLAN_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./meal-plan/meal-plan.module').then(
                (m) => m.MealPlanModule,
              ),
          },
          {
            path: ':uid',
            loadChildren: () =>
              import(
                './meal-plan/single-meal-plan/single-meal-plan.module'
              ).then((m) => m.SingleMealPlanModule),
          },
        ],
      },
      {
        path: RouteConstants.DAILY_MEALS_ROUTE,

        children: [
          {
            path: '',
            loadChildren: () =>
              import('./daily-meals/daily-meals.module').then(
                (m) => m.DailyMealPlanModule,
              ),
          },

          {
            path: ':uid',
            loadChildren: () =>
              import(
                './daily-meals/single-daily-meals/single-daily-meals.module'
              ).then((m) => m.SingleDailyMealsModule),
          },
        ],
      },

      {
        path: RouteConstants.MEALS_ROUTE,
        loadChildren: () =>
          import('./meals/meals.module').then((m) => m.MealsModule),
      },

      {
        path: RouteConstants.HABITS_ROUTE,
        children: [
          {
            path: RouteConstants.HABITS_ROUTE,
            children: [
              {
                path: '',
                loadComponent: () => import('./habits/habits.component'),
              },
            ],
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './habits/habits-scheduler/habits-scheduler.component'
                  ).then((m) => m.HabitsSchedulerComponent),
              },
            ],
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.HABITS_ROUTE,
          },
        ],
      },

      {
        path: RouteConstants.REFLECTIONS_ROUTE,
        children: [
          {
            path: RouteConstants.REFLECTIONS_ROUTE,
            children: [
              {
                path: `${RouteConstants.EDIT_REFLECTION_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: RouteConstants.ADD_REFLECTION_ROUTE,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: `${RouteConstants.VIEW_REFLECTION_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
              },
              {
                path: '',
                loadComponent: () =>
                  import('./reflections/reflections/reflections.component'),
              },
            ],
          },
          {
            path: RouteConstants.QUESTIONS_ROUTE,
            loadComponent: () =>
              import('./reflections/questions/questions.component'),
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            loadComponent: () =>
              import(
                './reflections/reflections-scheduler/reflections-scheduler.component'
              ).then((m) => m.ReflectionsSchedulerComponent),
          },

          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.REFLECTIONS_ROUTE,
          },
        ],
      },
      {
        path: RouteConstants.QUIZ_ROUTE,

        children: [
          {
            path: RouteConstants.QUIZZES_ROUTE,
            children: [
              {
                path: `${RouteConstants.EDIT_QUIZ_ROUTE}/:uid`,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: `${RouteConstants.VIEW_QUIZ_ROUTE}/:uid`,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: RouteConstants.ADD_QUIZ_ROUTE,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: '',
                loadComponent: () => import('./quiz/quizzes/quizzes.component'),
              },
            ],
          },
          {
            path: RouteConstants.QUESTIONS_ROUTE,
            loadComponent: () => import('./quiz/questions/questions.component'),
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            loadComponent: () =>
              import('./quiz/quiz-scheduler/quiz-scheduler.component').then(
                (m) => m.QuizSchedulerComponent,
              ),
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.QUIZZES_ROUTE,
          },
        ],
      },
      {
        path: RouteConstants.PROFILE_ROUTE,
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },

      {
        path: '**',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
